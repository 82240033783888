import activity from './m-brainstorm-ideas.vue';

export default activity;

export const componentRegistrationData = {
  name: 'Brainstorm Ideas',
  icon: 'mdi-head-snowflake',
  description: 'Brainstorm ideas',
  status: true,
  styling: {
    borderColor: '#fec34b'
  },
  setupRequired: true,
  fields: [
    {
      roles: ['employer', 'participant'],
      name: 'm-brainstorm-ideas',
      type: 'molecule',
      position: 0,
      preset: {},
      setup: {
        airtableLink: '',
        airtableResponseLink: ''
      },
      value: {}
    },
    {
      roles: ['employer', 'participant'],
      name: 'm-save-adk-only',
      type: 'molecule',
      position: 1,
      preset: {},
      setup: {},
      value: {}
    }
  ]
};
